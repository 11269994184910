<template>
  <div class="card card-primary card-outline">
    <div class="card-header">
      <h5 class="card-title m-0"><i class="fa fa-list"></i> List Rating</h5>
    </div>
    <div class="card-body">
      <p class="alert alert-light">
        <i class="fa fa-info-circle"></i> Search hanya mencari
        <code>nama</code>, <code>message</code> dan <code>waktu</code>
      </p>
      <!--filter & length-->
      <div class="d-flex justify-content-between">
        <div class="mb-2">
          <b-form-select
            :options="[5, 10, 15, 20]"
            :value="5"
            v-model="perPage"
          ></b-form-select>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <input type="date" class="form-control" v-model="form.awal" /> &nbsp;
          <span class="badge badge-success">s/d</span> &nbsp;
          <input type="date" class="form-control" v-model="form.akhir" /> &nbsp;
          <b-form-input
            type="search"
            placeholder="Search"
            v-model="form.search"
          ></b-form-input>
          &nbsp;
          <button class="btn btn-info" @click="findRecords">Find</button>
        </div>
      </div>
      <hr />
      <b-table
        id="my-rating"
        hover
        show-empty
        striped
        :fields="fields"
        :items="getRatingRecord"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #table-busy>
          <div class="text-center text-warning my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading...</strong>
          </div>
        </template>

        <template #cell(id)="data">
          {{ data.item.threadid }} <small>sub {{ data.item.subid }}</small>
        </template>

        <template #cell(nama)="data">
          <router-link
            :to="{ path: `/panel/chat/${data.item.subidhash}?ref=rating` }"
            >{{ data.item.nama }}</router-link
          >
        </template>

        <template #cell(rating)="data">
          <span v-html="getStar(data.item.rating)"></span>
        </template>

        <!--actions-->
        <template #cell(action)="data">
          <router-link
            class="btn btn-success btn-sm"
            :to="{ path: `/panel/chat/${data.item.subidhash}?ref=rating` }"
            ><i class="fa fa-eye"></i> Detail</router-link
          >
        </template>
      </b-table>

      <br />
      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="my-rating"
        align="right"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { request } from "@/common/mixins/mix_helper";
export default {
  name: "rating",
  mixins: [request],
  data() {
    return {
      form: {
        search: "",
        awal: null,
        akhir: null,
      },
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      sortCurrent: null,
      fields: [
        {
          key: "id",
          label: "Thread",
          sortable: false,
          class: "text-center",
        },
        {
          key: "nama",
          label: "Customer",
          sortable: false,
        },
        {
          key: "rating",
          sortable: true,
        },
        {
          key: "message",
          sortable: false,
        },
        {
          label: "Waktu",
          key: "waktu",
          sortable: false,
        },
        {
          key: "action",
          label: "#",
          class: "text-center",
        },
      ],
    };
  },
  methods: {
    /**
     * --------------------------------------------------
     * generate bintang
     * --------------------------------------------------
     */
    getStar: function (rating) {
      let star = "";
      for (let i = 1; i <= 5; i++) {
        if (i <= parseInt(rating)) {
          star += `<i class="fa fa-star text-orange"></i>`;
        } else {
          star += `<i class="fa fa-star"></i>`;
        }
      }
      return star;
    },

    /**
     * --------------------------------------------------
     * req restapi
     * --------------------------------------------------
     */
    getRatingRecord: function (ctx, callback) {
      let sortFlag = ctx.sortBy + "-" + ctx.sortDesc;
      if (sortFlag !== this.sortCurrent) {
        this.sortCurrent = sortFlag;
        this.currentPage = 1;
      }

      let params = {
        length: this.perPage,
        page: this.currentPage - 1,
        keyword: this.form.search,
        sort: {
          kolom: ctx.sortBy,
          dir: ctx.sortDesc ? "desc" : "asc",
        },
      };
      if (this.form.awal !== "" && this.form.akhir !== "") {
        let range = {
          startDate: this.form.awal,
          endDate: this.form.akhir,
        };
        params = { ...params, ...range };
      }
      this.API.post(this.URL.rating.agent, params)
        .then(({ data }) => {
          this.totalRows = data.data.total;
          callback(data.data.records);
        })
        .catch(() => {
          callback([]);
        });
      return null;
    },

    /**
     * --------------------------------------------------
     * button action find
     * --------------------------------------------------
     */
    findRecords() {
      this.$root.$emit("bv::refresh::table", "my-rating");
    },
  },
  mounted() {
    this.$store.dispatch("breadcrumb", {
      title: "MY Rating Page",
      path: "Rating",
    });
    this.$store.dispatch("activeLink", this.$route.path);
  },
};
</script>

<style scoped></style>
